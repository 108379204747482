import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Deload Week`}</em></p>
    <p>{`Hollow Rocks 3×1:00`}</p>
    <p>{`Hyperextensions 3×12`}</p>
    <p>{`DB Deadlifts 3×12`}</p>
    <p>{`then,`}</p>
    <p>{`15:00 EMOM, alternating movements:`}</p>
    <p>{`Minutes 1, 4, 7, 10, 13: 10-DB Burpee Deadlifts (50/35’s)`}</p>
    <p>{`Minutes 2, 5, 8, 11, 14: 15/10 Calorie Row`}</p>
    <p>{`Minutes 3, 6, 9, 12, 15: 15-Box Jumps (24/20)`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      